@tailwind base;

h1 {
    @apply text-2xl font-bold;
}
h2 {
    @apply text-xl;
}
h3 {
    @apply text-lg text-center;
}


/* background: */
progress::-webkit-progress-bar {background-color: black;}
progress {background-color: black;}

/* value: */
progress::-webkit-progress-value {background-color: #FF0000 !important;}
progress::-moz-progress-bar {background-color: #FF0000 !important;}
progress {color: #FF0000;}


@tailwind components;
@tailwind utilities;


@layer base {
    @font-face {
      font-family: 'droid-sans-mono';
      font-style: normal;
      font-weight: 400;
      /* font-display: swap; */
      src: url(./fonts/droid-sans-mono.ttf) format('ttf');
    }
  }